import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {provideStates} from '@ngxs/store';
import {AuthState} from './auth.state';
import {AuthService} from './auth.service';
import {withStorageFeature} from '@ngxs/storage-plugin';

export function initializeAuth(auth: AuthService) {
  return () => auth.init();
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAuth,
      deps: [AuthService],
      multi: true,
    },
    provideStates([AuthState], withStorageFeature([AuthState]))
  ],
})
export class AuthModule {
}
